import React, { useEffect, useState } from "react";
import styles from "./ScanAnnotation.module.css";
import ImageEditor  from "@toast-ui/react-image-editor";
import { UserScanLink, UserScanUpload } from "../models";
import { postCheckInUserScan, putCheckInUserScan, putS3File } from "../services";
import { Button } from "../../shared";
import Formsy from "formsy-react";
import FormInput from "../../shared/input/FormInput";
import FormTextArea from "../../shared/input/FormTextArea";

interface Props {
  userId: string;
  scanId: number;
}

interface EditImages {
  link: string;
  position: string;
}

export const ScanAnnotation: React.FC<Props> = ({ userId, scanId }) => {
  const [editorRef] = useState<any>(React.createRef());
  const [scan, setScan] = useState<UserScanUpload | undefined>(undefined);
  const [images, setImages] = useState<EditImages[]>([]);
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [areas, setAreas] = useState<number | undefined>();
  const [message, setMessage] = useState<string | undefined>();
  const [formMessage, setFormMessage] = useState<string | undefined>();
  const positions = images.map(it => it.position);

  const getPositionFromIndex = (index: number): string => {
    if (index === 0) {
      return "Front";
    } else if (index === 1) {
      return "Left";
    } else {
      return "Right";
    }
  };

  const getPositionIndex = (position: string): number => {
    if (position === "Front") {
      return 0;
    } else if (position === "Left") {
      return 1;
    } else {
      return 2;
    }
  };

  const loadImage = async (image: UserScanLink, localImages: EditImages[] = [], annotations: UserScanLink[] = []) => {
    const imageEditor = editorRef.current.getInstance();
    let imageToLoad = image;

    if (localImages.length || annotations.length) {
      const existingImage = localImages.find(it => it.position === image.position) ?? annotations.find(it => it.position === image.position);

      if (existingImage) {
        imageToLoad = existingImage;
      }
    }

    await imageEditor.loadImageFromURL(imageToLoad.link, imageToLoad.position);

    imageEditor.clearUndoStack(); // prevent removing loaded image with undo

    setPage(getPositionIndex(image.position));
  };

  const deleteImage = (position: string) => {
    const newImages = images.filter(image => image.position !== position);
    setImages(newImages);
  };

  const save = async (position: string) => {
    const imageEditor = editorRef.current.getInstance();

    setIsLoading(true);
    
    await imageEditor.applyFilter("Grayscale");
    const imageBase64 = imageEditor.toDataURL({ format: "jpeg", quality: 0.7 });

    // Upload image to S3
    const uploadUrl = scan?.uploadUrls?.find(it => it.position === position)?.link;

    // Apply gray filter on S3 file
    if (uploadUrl) {
      await putS3File(uploadUrl, Buffer.from(imageBase64.replace(/^data:image\/\w+;base64,/, ""), "base64"), "binary/octet-stream");
      imageEditor.clearUndoStack(); // prevent seeing gray filter
    }

    // Save files locally
    const newImages = images.filter(image => image.position !== position);
    newImages.push({ position, link: imageBase64 });

    setImages(newImages);
    setIsLoading(false);
  };

  const changePage = async (index: number) => {
    const imageEditor = editorRef.current.getInstance();

    if (scan) {
      if (!imageEditor.isEmptyUndoStack() && window.confirm("Do you want to save changes to current position?")) {
        await save(getPositionFromIndex(page));
      }

      await loadImage(scan.inputs[index], images, scan.annotations);
    }
  };

  useEffect(() => {
    const fetchScans = async () => {
      const data = await postCheckInUserScan(userId, scanId);
      setScan(data);

      const annotation = data.annotations?.find(it => it.message.length > 0);

      if (annotation) {
        setMessage(annotation.message);
        setAreas(annotation.areas)
      }

      if (data.inputs.length) {
        await loadImage(data.inputs[0], [], data.annotations);
      }
    };

    fetchScans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, scanId]);

  return (
    <div className={styles.root}>
      {scan && (
        <>
          <div className={styles.navbar}>
            <Button
              color="transparent"
              title="< Go to user scans"
              to={`/practitioners/scans/${scan?.userId}`}
            />
            <h6 className={styles.textCenter}>Scan #{scan.id} {scan.name} ({scan.email})</h6>
            <div />
          </div>
          <div className={styles.container}>
            <div className={styles.editor}>
              <div className={styles.actions}>
                <Button
                  color="transparent"
                  title="Reset"
                  disabled={isLoading}
                  onClick={async () => {
                    const input = scan.inputs.find(it => it.position === getPositionFromIndex(page));

                    if (input && window.confirm(`Are you sure you want to reset annotation?`)) {
                      deleteImage(input.position);
                      await loadImage(input);
                    }
                  }}
                />
                <Button
                  color="transparent"
                  title="Undo"
                  disabled={isLoading}
                  onClick={async () => {
                    const imageEditor = editorRef.current.getInstance();

                    if (!imageEditor.isEmptyUndoStack()) {
                      imageEditor.undo();
                    }
                  }}
                />
                <Button
                  color="transparent"
                  title="Redo"
                  disabled={isLoading}
                  onClick={async () => {
                    const imageEditor = editorRef.current.getInstance();

                    if (!imageEditor.isEmptyRedoStack()) {
                      imageEditor.redo();
                    }
                  }}
                />
                <Button
                  color="transparent"
                  title="Hand"
                  disabled={isLoading}
                  onClick={async () => {
                    const imageEditor = editorRef.current.getInstance();
                    imageEditor.stopDrawingMode();
                  }}
                />
                <Button
                  color="transparent"
                  title="Circle"
                  disabled={isLoading}
                  onClick={async () => {
                    const imageEditor = editorRef.current.getInstance();
                    imageEditor.stopDrawingMode();
                    imageEditor.addShape("circle", {
                      fill: "transparent",
                      stroke: "blue",
                      strokeWidth: 15,
                      top: 120,
                      left: 120,
                      rx: 100,
                      ry: 100,
                      isRegular: true
                    });
                  }}
                />
                <Button
                  color="transparent"
                  title="Text"
                  disabled={isLoading}
                  onClick={async () => {
                    const imageEditor = editorRef.current.getInstance();
                    imageEditor.stopDrawingMode();
                    imageEditor.addText("Lorem ipsum\ndolor sit amet.", {
                      styles: {
                        fill: "white",
                        fontWeight: "bold",
                        fontSize: 150
                      },
                      position: {
                        x: 10,
                        y: 10
                      },
                      autofocus: false
                    });
                  }}
                />
                <Button
                  color="transparent"
                  title="Arrow"
                  disabled={isLoading}
                  onClick={async () => {
                    const imageEditor = editorRef.current.getInstance();
                    if (imageEditor.getDrawingMode() === "LINE_DRAWING") {
                      imageEditor.stopDrawingMode();
                    } else {
                      imageEditor.startDrawingMode("LINE_DRAWING", {
                        width: 15,
                        color: "black",
                        arrowType: {
                          tail: "triangle"
                        }
                      });
                    }
                  }}
                />
                <Button
                  color="transparent"
                  title="Save"
                  disabled={isLoading}
                  onClick={async () => {
                    await save(getPositionFromIndex(page));
                  }}
                />
              </div>
              <div className={styles.arrows} style={{ visibility: isLoading ? "hidden" : "visible" }}>
                <div className={styles.image}>
                  <ImageEditor
                    ref={editorRef}
                    usageStatistics={false}
                    cssMaxHeight={500}
                    cssMaxWidth={700}
                  />
                </div>
                <Button
                  color="transparent"
                  className={styles.left}
                  title="<"
                  disabled={isLoading || page <= 0}
                  onClick={() => changePage(page - 1)}
                />
                <Button
                  color="transparent"
                  className={styles.right}
                  title=">"
                  disabled={isLoading || (page + 1) >= 3}
                  onClick={() => changePage(page + 1)}
                />
              </div>
            </div>
            <div className={styles.form}>
              {<p>{formMessage ?? (positions.length ? `Images annotated: ${positions.join(", ")}` : "Annotate all images and click Save.")}</p>}
              <Formsy
                onValidSubmit={async (model) => {
                  if (positions.length === 3 && !isLoading) {
                    setFormMessage(undefined);

                    if (window.confirm(`Are you sure you want to send annotations?`)) {
                      await putCheckInUserScan(userId, scanId, positions, model.message, Number(model.areas));
                      setFormMessage(`Sent sucessfully!`);
                    }
                  } else {
                    setFormMessage("Annotate all images and click Save.");
                  }
                }}
              >
                <FormInput
                  name="areas"
                  label="Focus areas*"
                  placeholder="Number of focus areas"
                  value={areas?.toString()}
                  type="number"
                  min={0}
                  required
                />
                <FormTextArea
                  name="message"
                  label="Message*"
                  placeholder="Leave a message for the user"
                  value={message}
                  className={styles.textarea}
                  required
                />
                <Button
                  type="submit"
                  color="blue"
                  title="Send to user"
                  fullWidth
                  className={styles.button}
                  disabled={isLoading || positions.length < 3}
                />
              </Formsy>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
